<template>
  <div style="overflow: hidden">
    <div>
      <img
        src="../../assets/img/dyBanner.png"
        style="display: block; width: 100%"
      />
    </div>
    <div class="dynamics">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="领导关怀" name="first">
          <div
            class="tabCon-item"
            v-for="(items, index) in companyData.slice(
              (currentPage1 - 1) * pagesize,
              currentPage1 * pagesize
            )"
            :key="index"
            @click="goCompanyNews(items.companyNewsId)"
            :style="{ animationDelay: delayNumber * index + 's' }"
          >
            <div class="tabCon-item1">
              <div class="com-img">
                <img :src="items.imageUrl" alt />
              </div>
              <div class="com-content">
                <p class="dy-title ellipsis">{{ items.companyNewsTitle }}</p>
                <p class="dy-content ellipsis-3">
                  {{ items.companyNewsSubTitle }}
                </p>
                <p class="dy-time">
                  {{ items.createDtme | formatDate(items.createDtme) }}
                </p>
              </div>
            </div>
          </div>
          <div style="margin: 35px 0 88px 0">
            <nav style="text-align: center">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="pagesize"
                :total="total1"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage1"
                :hide-on-single-page="false"
              ></el-pagination>
            </nav>
          </div>
        </el-tab-pane>
        <el-tab-pane label="行业资讯" name="second">
          <div
            class="tabCon-item"
            v-for="(items, index) in industryData.slice(
              (currentPage2 - 1) * pagesize,
              currentPage2 * pagesize
            )"
            :key="index"
            @click="goIndustryNews(items.industryNewsId)"
            :style="{ animationDelay: delayNumber * index + 's' }"
          >
            <div class="tabCon-item1">
              <div class="com-img">
                <img :src="items.imageUrl" alt />
              </div>
              <div class="com-content">
                <p class="dy-title ellipsis">{{ items.industryNewTitle }}</p>
                <p class="dy-content ellipsis-3">
                  {{ items.industryNewSubTitle }}
                </p>
                <p class="dy-time">
                  {{ items.createDtme | formatDate(items.createDtme) }}
                </p>
              </div>
            </div>
          </div>
          <div style="margin: 35px 0 88px 0">
            <nav style="text-align: center">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="pagesize"
                :total="total2"
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :current-page="currentPage2"
              ></el-pagination>
            </nav>
          </div>
        </el-tab-pane>
        <el-tab-pane label="环保百科" name="third" style>
          <div
            class="tabCon-item"
            v-for="(items, index) in epData.slice(
              (currentPage3 - 1) * pagesize,
              currentPage3 * pagesize
            )"
            :key="index"
            @click="goEp(items.epId)"
            :style="{ animationDelay: delayNumber * index + 's' }"
          >
            <div class="tabCon-item1">
              <div class="com-img">
                <img :src="items.imageUrl" alt />
              </div>
              <div class="com-content">
                <p class="dy-title ellipsis">{{ items.epTitle }}</p>
                <p class="dy-content ellipsis-3">{{ items.epSubTitle }}</p>
                <p class="dy-time">
                  {{ items.createDtme | formatDate(items.createDtme) }}
                </p>
              </div>
            </div>
          </div>
          <div style="margin: 35px 0 88px 0">
            <nav style="text-align: center">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="pagesize"
                :total="total3"
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChangess"
                :current-page="currentPage3"
              ></el-pagination>
            </nav>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      pagesize: 8, //    每页的数据
      companyData: [],
      industryData: [],
      epData: [],
      total1: 0,
      total2: 0,
      total3: 0,
      activeName: "first",
      companyInfo: [],
      delayNumber: 0.4,
      pageValue: false,
    };
  },
  updated() {
    window.scroll(0, 0);
  },
  methods: {
    handleClick(tab, event) {
      //console.log(event);
    },
    goCompanyNews(i) {
      console.log(i);
      let routeData = this.$router.resolve({
        path: "companyNews",
        query: { id: i },
      });
        window.open(routeData.href, "_blank");
    },
    goIndustryNews(i) {
      console.log(i)
      let routeData = this.$router.resolve({
        path: "industryNews",
        query: { id: i },
      });
      window.open(routeData.href, "_blank");
    },
    goEp(i) {
      //console.log(i)
      let routeData = this.$router.resolve({ 
          path: "ep", 
          query: { id: i } 
        });
      window.open(routeData.href, "_blank");
    },
    getCompanyNews: function () {
      var that = this;
      //console.log(that.$api.showCompanyNews);
      that
        .$http(that.$api.showCompanyNews + "?limit=10000")
        .then((res) => {
          let conts = res.data.result;
          //console.log(conts)
          for (let i = 0; i < conts.length; i++) {
            if (conts[i].status == 0) {
              that.companyData.push(conts[i]);
            }
          }
          //console.log(that.companyData);
          that.total1 = that.companyData.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getIndustryNews: function () {
      var that = this;
      //console.log(that.currentPage)
      that
        .$http(that.$api.showIndustryNews + "?limit=10000")
        .then((res) => {
          let conts = res.data.result;
          for (let i = 0; i < conts.length; i++) {
            if (conts[i].status == 0) {
              that.industryData.push(conts[i]);
            }
          }
          that.total2 = that.industryData.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEp: function () {
      var that = this;
      that
        .$http(that.$api.showEp + "?limit=10000")
        .then((res) => {
          let conts = res.data.result;
          // console.log(conts)
          for (let i = 0; i < conts.length; i++) {
            if (conts[i].status == 0) {
              that.epData.push(conts[i]);
            }
          }
          that.total3 = that.epData.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.pagesize = val;
      window.scroll(0, 400);
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`,val);
      this.currentPage1 = val;
      window.scroll(0, 400);
    },
    handleSizeChanges(val) {
      //console.log(`每页 ${val} 条`);
      this.pagesize = val;
      window.scroll(0, 400);
    },
    handleCurrentChanges(val) {
      //console.log(`当前页: ${val}`,val);
      this.currentPage2 = val;
      window.scroll(0, 400);
    },
    handleSizeChangess(val) {
      //console.log(`每页 ${val} 条`);
      this.pagesize = val;
      window.scroll(0, 400);
    },
    handleCurrentChangess(val) {
      //console.log(`当前页: ${val}`,val);
      this.currentPage3 = val;
      window.scroll(0, 400);
    },
  },

  mounted: function () {
    //调取本地的get(就在下面)
    this.getCompanyNews();
    this.getIndustryNews();
    this.getEp();
  },
};
</script>

<style>
.dynamics {
  width: 1017px;
  margin: 0 auto;
}

.tabCon-item {
  padding: 0 26px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  animation: fadeInUp 0.6s ease backwards;
  -webkit-animation: fadeInUp 0.6s ease backwards;
}

.tabCon-item:hover {
  background: rgba(2, 133, 255, 0.1);
  box-shadow: 0px 30px 56px 4px rgba(190, 202, 230, 0.33);
  border-bottom: 1px solid #e5e5e5;
}

.tabCon-item:hover .tabCon-item1 {
  border-bottom: 1px solid transparent;
}

.tabCon-item:hover .com-img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  /* IE 9 */
  -moz-transform: scale(1.05);
  /* Firefox */
  -webkit-transform: scale(1.05);
  /* Safari 和 Chrome */
  -o-transform: scale(1.05);
  /* Opera */
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  /* Firefox 4 */
  -webkit-transition: all 0.5s ease;
  /* Safari and Chrome */
  -o-transition: all 0.5s ease;
  /* Opera */
}

.tabCon-item1 {
  width: 954px;
  padding: 36px 0;
  border-bottom: 1px solid #e5e5e5;
}

.com-img,
.com-content {
  display: inline-block;
  vertical-align: bottom;
}

.com-img {
  width: 236px;
  height: 176px;
}

.com-img img {
  width: 100%;
  height: 100%;
}

.com-content {
  width: 685px;
  height: 160px;
  margin-left: 26px;
  position: relative;
}

.dy-title {
  font-size: 22px;
  color: #0c0d0d;
  font-weight: bold;
}

.dy-content {
  font-size: 16px;
  color: rgba(12, 13, 13, 0.85);
  margin-top: 20px;
}

.dy-time {
  font-size: 14px;
  color: rgba(102, 102, 102, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
}

#pane-first,
#pane-second,
#pane-third {
  margin-top: 64px;
}
.el-tabs__nav {
  width: 100%;
}
.dynamics .el-tabs__item {
  width: 339px;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 82px !important;
  line-height: 82px !important;
}
.el-tabs__active-bar {
  width: 339px;
  margin: 0;
  padding: 0;
  height: 3px;
}
.el-tabs__item.is-active {
  color: #0285ff !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  height: 35px;
  line-height: 35px;
  min-width: 35px;
  margin: 0 7px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0285ff !important;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 7%, 0);
    transform: translate3d(0, 7%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 7%, 0);
    transform: translate3d(0, 7%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
</style>