var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"overflow":"hidden"}},[_vm._m(0),_c('div',{staticClass:"dynamics"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"领导关怀","name":"first"}},[_vm._l((_vm.companyData.slice(
            (_vm.currentPage1 - 1) * _vm.pagesize,
            _vm.currentPage1 * _vm.pagesize
          )),function(items,index){return _c('div',{key:index,staticClass:"tabCon-item",style:({ animationDelay: _vm.delayNumber * index + 's' }),on:{"click":function($event){return _vm.goCompanyNews(items.companyNewsId)}}},[_c('div',{staticClass:"tabCon-item1"},[_c('div',{staticClass:"com-img"},[_c('img',{attrs:{"src":items.imageUrl,"alt":""}})]),_c('div',{staticClass:"com-content"},[_c('p',{staticClass:"dy-title ellipsis"},[_vm._v(_vm._s(items.companyNewsTitle))]),_c('p',{staticClass:"dy-content ellipsis-3"},[_vm._v(" "+_vm._s(items.companyNewsSubTitle)+" ")]),_c('p',{staticClass:"dy-time"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(items.createDtme,items.createDtme))+" ")])])])])}),_c('div',{staticStyle:{"margin":"35px 0 88px 0"}},[_c('nav',{staticStyle:{"text-align":"center"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pagesize,"total":_vm.total1,"current-page":_vm.currentPage1,"hide-on-single-page":false},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],2),_c('el-tab-pane',{attrs:{"label":"行业资讯","name":"second"}},[_vm._l((_vm.industryData.slice(
            (_vm.currentPage2 - 1) * _vm.pagesize,
            _vm.currentPage2 * _vm.pagesize
          )),function(items,index){return _c('div',{key:index,staticClass:"tabCon-item",style:({ animationDelay: _vm.delayNumber * index + 's' }),on:{"click":function($event){return _vm.goIndustryNews(items.industryNewsId)}}},[_c('div',{staticClass:"tabCon-item1"},[_c('div',{staticClass:"com-img"},[_c('img',{attrs:{"src":items.imageUrl,"alt":""}})]),_c('div',{staticClass:"com-content"},[_c('p',{staticClass:"dy-title ellipsis"},[_vm._v(_vm._s(items.industryNewTitle))]),_c('p',{staticClass:"dy-content ellipsis-3"},[_vm._v(" "+_vm._s(items.industryNewSubTitle)+" ")]),_c('p',{staticClass:"dy-time"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(items.createDtme,items.createDtme))+" ")])])])])}),_c('div',{staticStyle:{"margin":"35px 0 88px 0"}},[_c('nav',{staticStyle:{"text-align":"center"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pagesize,"total":_vm.total2,"current-page":_vm.currentPage2},on:{"size-change":_vm.handleSizeChanges,"current-change":_vm.handleCurrentChanges}})],1)])],2),_c('el-tab-pane',{attrs:{"label":"环保百科","name":"third"}},[_vm._l((_vm.epData.slice(
            (_vm.currentPage3 - 1) * _vm.pagesize,
            _vm.currentPage3 * _vm.pagesize
          )),function(items,index){return _c('div',{key:index,staticClass:"tabCon-item",style:({ animationDelay: _vm.delayNumber * index + 's' }),on:{"click":function($event){return _vm.goEp(items.epId)}}},[_c('div',{staticClass:"tabCon-item1"},[_c('div',{staticClass:"com-img"},[_c('img',{attrs:{"src":items.imageUrl,"alt":""}})]),_c('div',{staticClass:"com-content"},[_c('p',{staticClass:"dy-title ellipsis"},[_vm._v(_vm._s(items.epTitle))]),_c('p',{staticClass:"dy-content ellipsis-3"},[_vm._v(_vm._s(items.epSubTitle))]),_c('p',{staticClass:"dy-time"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(items.createDtme,items.createDtme))+" ")])])])])}),_c('div',{staticStyle:{"margin":"35px 0 88px 0"}},[_c('nav',{staticStyle:{"text-align":"center"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pagesize,"total":_vm.total3,"current-page":_vm.currentPage3},on:{"size-change":_vm.handleSizeChanges,"current-change":_vm.handleCurrentChangess}})],1)])],2)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticStyle:{"display":"block","width":"100%"},attrs:{"src":require("../../assets/img/dyBanner.png")}})])}]

export { render, staticRenderFns }